.footer {
  background: rgb(24 24 24);
  border-top: 1px solid rgba(255, 255, 255, 0.298);

  width: 100%;
  height: fit-content;

  position: relative;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;

  z-index: 4;

  padding: 3rem 0;
}

.texts {
  margin: 1.65em auto 0 auto;
  width: 90%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.text,
.link {
  margin: 0;
  font-family: sans-serif;
  font-style: normal;
  letter-spacing: 0em;
  text-align: center;

  font-size: 14px;
}

.text {
  color: #b6bcbe;
  font-weight: 400;
}

.text:not(:first-child) {
  margin-top: 12px;
}

.link {
  text-decoration: none;
  color: #eee;
  font-weight: 600;
}

.link:hover {
  color: #fff;
}
