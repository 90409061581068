.main {
  /* background: #141e30; */
  background: #000;

  min-height: 100vh;
  height: fit-content;

  flex-direction: row;
  gap: 16px;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 24px;

  max-width: 80%;

  margin: 0 auto;
}

.divider {
  width: 1px;
  height: 4rem;
  background: #eee;
}

.errorCode {
  font-family: sans-serif;
  font-weight: 600;
  font-size: 3rem;
  color: #fff;
  margin: 0;
}

.errorText {
  font-family: sans-serif;
  font-weight: 400;
  font-size: 1.75rem;
  color: #ddd;
  margin: 0;
}
