.nav {
  position: fixed;
  top: 0;

  height: fit-content;

  transition: 300ms;

  display: flex;
  flex-direction: row;
  justify-content: center;

  z-index: 4;
  opacity: 0;
}

.nav.large {
  padding: 48px;
  width: calc(100% - 96px);
}

.nav.small {
  padding: 32px;
  width: calc(100% - 64px);
}

.nav.background {
  background: #000;
}

.nav.show {
  opacity: 1;
}

.nav.zIndex {
  z-index: 5;
}

.navInner {
  max-width: 1800px;
  width: 100%;

  display: flex;
  flex-direction: row;
}

.navRight {
  margin-left: auto;

  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-items: center;
}

.header,
.navLeft {
  width: calc(100% / 3);

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}

.navLeft {
  z-index: 2;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.headerImage {
  filter: invert(1);

  width: 225px;
  aspect-ratio: 10/3;

  margin: 0;
}

.navToggle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 48px;
  height: 48px;

  box-shadow: rgba(255, 255, 255, 0.7) 0px 0px 3px,
    rgba(255, 255, 255, 0.7) 0px 0px 2px;
}

.allowClick .navToggle:hover {
  cursor: pointer;
}

.navToggle.toggled .lineOne {
  transform: translateY(5.5px) rotate(45deg);
}

.navToggle.toggled .lineTwo {
  transform: translateY(-5.5px) rotate(-45deg);
}

.navToggle:not(.toggled):hover .lineOne {
  transform: translateX(-2px);

  transition: 250ms;
}

.navToggle:not(.toggled):hover .lineTwo {
  transform: translateX(2px);

  transition: 250ms;
}

.lineHolder {
  width: 22px;
  height: 22px;

  padding: 0.25rem;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  transition: 250ms;
}

.lineOne,
.lineTwo {
  width: 100%;
  height: 1px;

  display: block;

  border: none;
  outline: none;
  background: #fff;

  transition: 250ms;
}

.mobileNav {
  position: fixed;

  top: 0;
  left: 0;

  background: #000;

  width: 100vw;
  height: 100vh;

  z-index: -1;
  opacity: 0;

  transition: opacity 300ms, z-index 301ms;
}

.mobileNav.active {
  opacity: 1;
  z-index: 4;

  transition: opacity 300ms;
}