.socialList {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.socialList.footer {
  gap: 32px;
}

.socialList.normal {
  gap: 1rem;
}

.socialList.footer a {
  width: 20px;
  height: 20px;
}

.socialList.normal a {
  width: 24px;
  height: 24px;
}

.socialImg {
  filter: contrast(0) brightness(1.5);

  transition: 250ms;
}

.socialImg:hover {
  filter: contrast(0) brightness(2);

  transition: 250ms;
}
