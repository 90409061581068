.tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  column-gap: 16px;
}

.tabs.footer {
  margin-bottom: 24px;
  flex-wrap: wrap;
  justify-content: center;
  width: 80vw;
}

.tab {
  font-family: "Trim";
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;
  white-space: nowrap;

  color: #ddd;
}

.tab.active {
  color: #fff;
}

.allowClick .tab:hover {
  color: #fff !important;
  cursor: pointer;
}

.tabs:not(.allowClick) .tab:hover {
  cursor: default;
}