.Layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* min-height: 100vh; */
  height: fit-content;
  width: 100vw;

  padding: var(--nextui--navbarHeight) 0;

  position: relative;
  z-index: 2;

  overflow-y: scroll;
  overflow-x: hidden;
}
