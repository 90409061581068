.tabs {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  row-gap: 16px;

  position: relative;

  top: 50%;
  transform: translateY(-50%);
}

.tab {
  font-family: "Trim";
  font-weight: 400;
  font-size: 32px;
  text-transform: uppercase;

  color: #ddd;
}

.tab.active {
  color: #fff;
}

.tab:hover {
  color: #fff !important;
  cursor: pointer;
}